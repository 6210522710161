import React from "react";

const TermsConditions = () => {
  return (
    <div className="xl:px-80 lg:px-40 px-5 py-20">
      <h1 className="font-bold text-center">TERMS & CONDITIONS</h1>
      <br />
      <h2 className="font-bold">1. Parties</h2>
      <br />
      <p className="pl-10">
        This Terms & Conditions (“<strong>Terms & Conditions</strong> ”) is
        entered into by and between;
      </p>
      <br />
      <div className="pl-10">
        <ul className="list-decimal pl-10">
          <li>
            Fantazzie Inc (“<strong>PICK SLAM </strong>”), registered under the
            Trade Registry Office of State of Delaware, United States.
          </li>
          <li>
            The user that signs up to the platform of PICK SLAM through the
            website registered to &nbsp;
            <a
              href="https://pickslam.com/"
              className="underline decoration-sky-500 text-sky-500"
              target="blank"
            >
              www.pickslam.com
            </a>
            &nbsp; domain address or Pick Slam mobile application ("
            <strong>User</strong>“).
          </li>
        </ul>
      </div>
      <br />
      <h2 className="font-bold">2. Definitions</h2>
      <br />
      <p className="pl-10">
        “<strong>PICK SLAM</strong>” means Fantazzie Inc, registered under the
        Trade Registry Office of State of Delaware, United States.
      </p>
      <br />
      <p className="pl-10">
        “<strong>Fantazzie Football</strong>” means the fantasy football game
        served by PICK SLAM through its Platform.
      </p>
      <br />
      <p className="pl-10">
        “<strong>Service(s)</strong>” means services provided by PICK SLAM and
        listed in the Platform, including Fantazzie Football.
      </p>
      <br />
      <p className="pl-10">
        “<strong>User</strong>” means yourself who approve the Terms &
        Conditions and sign up to the Platform through online application store
        or Web Site to use and play Fantazzie Football.
      </p>
      <br />
      <p className="pl-10">
        “<strong>Privacy Policy</strong>” means the informative document that
        can be accessed through{" "}
        <a
          href="https://pickslam.com/"
          className="underline decoration-sky-500 text-sky-500"
          target="blank"
        >
          https://pickslam.com/privacy-policy
        </a>{" "}
        and provides information regarding the personal data that is processed
        when the user signs up, accesses and uses the Platform.
      </p>
      <br />
      <p className="pl-10">
        “<strong>Mobil App</strong>” means the mobile application named Pick
        Slam.
      </p>
      <br />
      <p className="pl-10">
        “<strong>Introductory Information of PICK SLAM </strong>” means the
        introductory information regarding PICK SLAM that can be accessed
        through{" "}
        <a
          href="https://pickslam.com/"
          className="underline decoration-sky-500 text-sky-500"
          target="blank"
        >
          https://pickslam.com
        </a>{" "}
        .
      </p>
      <br />
      <p className="pl-10">
        “<strong>Platform</strong>” means the platform managed by PICK SLAM
        through the website registered to www.pickslam.com domain address or
        Pick Slam mobile application whereby you can access Fantazzie Football
        game.
      </p>
      <br />
      <p className="pl-10">
        “<strong>Cookie Policy</strong>” means the Cookie Policy that can be
        accessed through{" "}
        <a
          href="https://pickslam.com/"
          className="underline decoration-sky-500 text-sky-500"
          target="blank"
        >
          https://pickslam.com/cookies
        </a>{" "}
        .
      </p>
      <br />
      <p className="pl-10">
        “<strong>Virtual Product</strong>” has the meaning as defined in Article
        5.6.
      </p>
      <p className="pl-10">
        “<strong>Consumer Law</strong>” means the consumer laws and legislations
        that are in force in your Delaware, United States.
      </p>
      <br />
      <p className="pl-10">
        “<strong>Product(s)</strong>” means the virtual slam coins that can be
        acquired by using the Platform or other Virtual Products that can be
        acquired with the slam coins.
      </p>
      <br />
      <p className="pl-10">
        “<strong>Website</strong>” means the website registered to
        www.pickslam.com domain address;
      </p>
      <br />
      <br />
      <h2 className="font-bold">3. Subject of the Agreement</h2>
      <br />
      <p className="pl-10">
        This Terms & Conditions sets forth the rights and obligations of the
        Parties regarding the User’s registration to the Platform, usage of the
        Platform and utilization of the Products and Services provided by PICK
        SLAM
      </p>
      <br />
      <br />
      <h2 className="font-bold">4. Signing Up and Access</h2>
      <br />
      <ul className="list-decimal pl-10">
        <li>
          In order to sign up on the Platform, the User must enter the
          information requested by PICK SLAM in the relevant fields and approve
          this Terms & Conditions. Following this approval, PICK SLAM has the
          right to send activation messages to the contact addresses specified
          by the User in order to complete the User's registration.
        </li>
        <li>
          The User accepts that he/she knows the conditions of being over 18
          years old and fully competent to register on the Platform and confirms
          that he/she meets these conditions. The User declares that all the
          information he/she provides while registering on the Platform is
          correct, complete and up-to-date. The User will be responsible for any
          damages that may occur due to incorrect, incomplete or not updated
          information.
        </li>
        <li>
          The user's approval of this Terms & Conditions is binding and is a
          fundamental element for the validity of this agreement.
        </li>
        <li>
          Signing up on the Platform is free of charge. For the avoidance of
          doubt, PICK SLAM reserves the right to charge the registration to the
          Platform, and PICK SLAM will notify Users in case of any such change.
          If the User does not accept such change, their registration from the
          Platform will have to be deleted.
        </li>
        <li>
          Except for the exceptional cases specified in this Terms & Conditions,
          the User will always have the right to delete his/her registration
          from the Platform.
        </li>
        <li>
          After the User completes his/her registration to the Platform, he/she
          will be able to sign in to the Platform using the specified username
          and password.
        </li>
      </ul>
      <br />
      <br />
      <h2 className="font-bold">
        5. Terms and Conditions for Fantazzie Football and Other Products and
        Services
      </h2>
      <br />
      <ul className="list-decimal pl-10">
        <li>
          The rules regarding the Fantazzie Football game can be accessed from
          the link [ ] and the terms and conditions regarding the awards can be
          accessed from the link [ ]. By approving this Terms & Conditions, the
          User acknowledges that he/she also approves the terms and conditions
          regarding the game rules and awards.
        </li>
        <li>
          Users agree that the Products provided on the Platform are of no
          monetary value and may never be exchanged for real money, real
          products or real services by PICK SLAM or anyone else. Users agree
          that they will not transfer or attempt to transfer the Products/
          Services provided to them on the Platform to third parties.
        </li>
        <li>
          PICK SLAM has the sole authority to determine the Products and
          Services, the terms of the Products and Services and the criteria that
          must be met for the delivery of such 2 Products and Services. PICK
          SLAM, at its sole discretion, has the authority to change the terms
          for the provision of the Services and Products at any time.
        </li>
        <li>
          PICK SLAM reserves the right to moderate, edit, modify or remove any
          Products and Services at its sole discretion
        </li>
        <li>
          In accordance with the provisions of this Terms & Conditions, Users
          may lose the Products or Services they had received in the event that
          their accounts are suspended or terminated and in such case they will
          not be refunded any real money, real products or services.
        </li>
        <li>
          Users will be able to obtain a limited, non-transferable, non-sub
          licensable, revocable (by PICK SLAM) right of usage of the virtual
          slam coin and all (i) in game virtual values and (ii) in game virtual
          items [(i) and (ii) altogether “<strong>Virtual Products</strong>”] on
          the Platform for their own personal and non-commercial entertainment
          to use in Fantazzie Football games. Virtual slam coin and Virtual
          Products can only be obtained through the Platform and only for using
          in Fantazzie Football, and no other acquisition of such Virtual
          Products is possible.
        </li>
        <li>
          The User agrees that no refund or replacement (money or other supply
          of goods or services or by any other means) will be made for the
          Products he/she has received (regardless of his/her preference of
          using or not using the Products) during the User's usage of the
          Platform or in the event that PICK SLAM terminates/suspends the User's
          account or the User terminates his/her account voluntarily
        </li>
      </ul>
      <br />
      <br />
      <h2 className="font-bold">6. Rights and Obligations of the User</h2>
      <br />
      <ul className="list-decimal pl-10">
        <li>
          The User has the right to register on the Platform. The User is
          obliged to securely protect the username and password allocated to
          him/her for the usage of the Platform and shall not share it with the
          third parties. The User will be responsible for all damages that may
          arise from the malicious use of his account by other people due to
          his/her own fault.
        </li>
        <li>
          The user shall not transfer to the third parties the usage rights,
          user name and password of the Platform.
        </li>
        <li>
          The User shall use Fantazzie Football and other Products and Services
          in accordance with the applicable legislation, Terms & Conditions,
          Privacy Policy, and Fantazzie Football rules, and accepts he/she is
          responsible of the legal & criminal matters that may arise from
          his/her transactions, including navigating the main page and subpages,
          accessing any content and accepts that he/she will comply with the
          relevant rules, terms and conditions. The user shall not take any
          damaging actions against PICK SLAM, other users and/or third parties
          by using Fantazzie Football
        </li>
        <li>
          The user holds the right to close his/her account at any time. Users
          agree that they may lose their access to data previously associated
          with their accounts, in the event that Users close their accounts or
          if the User account is suspended or restricted by PICK SLAM pursuant
          to the provisions of this Agreement.
        </li>
        <li>
          All possible precautions to protect the Platform from viruses and
          similar malicious software are taken by PICK SLAM. However, the User
          shall take his/her own measures to protect the devices from which
          he/she will access the Platform.
        </li>
        <li>
          The User accepts that in the event that his/her activities contrary to
          ethical/moral values and laws are detected while using the Platform,
          PICK SLAM can take legal action against the user as well restricting
          or preventing the usage of the Platform.
        </li>
      </ul>
      <br />
      <br />
      <h2 className="font-bold">7. Rights and Obligations of PICK SLAM</h2>
      <br />
      <ul className="list-decimal pl-10">
        <li>
          PICK SLAM reserves the right to change and reorganize the appearance,
          operation and terms of usage of the Platform, temporarily cease the
          access to the Platform and to close the Platform permanently, without
          a prior notice.
        </li>
        <li>
          PICK SLAM is entitled to add new Products and Services to the
          Platform, to change the scope, conditions and content of the existing
          Products and Services, and to remove existing Products and Services at
          any time.
        </li>
        <li>
          PICK SLAM may provide promotional opportunities related to the
          Products and Services on the Platform from time to time. PICK SLAM may
          unilaterally terminate the provision of such promotional Products and
          Services and start providing the relevant Products and Services
          without promotion at any time. PICK SLAM has no obligation to provide
          a minimum number of promotional Products and Services to the User. In
          the event that PICK SLAM provides coupons for the User within the
          scope of such promotions, the User will have the right to use the
          relevant coupon only for the Products and Services and will not have
          any right to claim the monetary compensation of the coupons.
        </li>
        <li>
          .The user accepts and agrees that PICK SLAM may provide links to other
          websites and/or platforms, files or contents through the Platform,
          that do not belong to PICK SLAM and that PICK SLAM does not provide
          any guarantees regarding the safety and quality of such websites or
          platforms and that PICK SLAM shall not be liable from the damages that
          may arise in connection with the platforms and content accessed this
          way.
        </li>
        <li>
          In order to ensure the security of the Platform, PICK SLAM has the
          right to prevent access to the Platform from certain locations or
          certain devices or IPs.
        </li>
        <li>
          PICK SLAM shall take all possible measures to ensure the uninterrupted
          and secure operation of the Platform. However, PICK SLAM does not
          guarantee that any malfunctions, errors or interruptions will not
          occur on the Platform. Users shall also take their own precautions to
          ensure the security of the devices by which they access the Platform.
        </li>
        <li>
          PICK SLAM holds the right to suspend Users' accounts on the Platform,
          or restrict their use, or even prevent them from re-registering to the
          Platform, at PICK SLAM's sole discretion, in the event that Users’
          violation of their obligations arising from this Terms & Conditions or
          Users’ usage of the Platform without good faith or with an unsuitable
          manner for the purpose of the Platform is determined.
        </li>
      </ul>
      <br />
      <br />
      <h2 className="font-bold">8. Intellectual Property</h2>
      <br />
      <p className="pl-10">
        PICK SLAM is the real and legal owner of all material and moral rights,
        intellectual and industrial property rights and all the interests in
        connection with “Pick Slam" brand, domain, software, Platform, all data
        (except for the personal data of the User) and related materials and
        trade secrets on the Platform, and the User is not entitled to use,
        modify, copy or dispose them without permission. PICK SLAM reserves the
        right to take any legal action against any infringement or violation of
        intellectual property rights.
      </p>
      <br />
      <br />
      <h2 className="font-bold">9. Confidentiality and Personal Data</h2>
      <br />
      <ul className="list-decimal pl-10">
        <li>
          The User's personal data will be processed by PICK SLAM only to the
          extent necessary, in accordance with the personal data protection
          legislation and within the scope and purposes specified in the Privacy
          Policy. The User accepts and declares that he/she has read the Privacy
          Policy and Cookie Policy presented to him/her before approving this
          Terms & Conditions.
        </li>
        <li>
          The User undertakes not to process and transfer the personal data of
          other users and other third parties that he/she received while using
          the Platform, in a way that creates violation of the personal data
          protection legislation. The User will be responsible for any
          violations he/she commit regarding any personal data.
        </li>
      </ul>
      <br />
      <br />
      <h2 className="font-bold">10. Compliance with the Consumer Law</h2>
      <br />
      <ul className="list-decimal pl-10">
        <li>
          PICK SLAM is liable to provide the Products and Services to the Users
          in accordance with the Consumer Law and other legislation and to
          fulfill its obligations in accordance with the legislation.
        </li>
        <li>
          .Users who are provided with PICK SLAM Products and Services shall
          have the right to apply to Consumer Arbitration Committees and/or
          Consumer Courts and other legal authorities of Delaware, United
          States, in the event that they consider the Services do not comply
          with the Consumer Law
        </li>
      </ul>
      <br />
      <br />
      <h2 className="font-bold">11. Notifications</h2>
      <br />
      <ul className="list-decimal pl-10">
        <li>
          For the activation and other necessary situations, PICK SLAM will
          communicate with the User through the contact addresses provided by
          the User while signing up on the Platform. In case of a change in the
          contact addresses of the User, the User shall update these information
          on the Platform.
        </li>
        <li>
          .The User can use the communication channels specified in PICK SLAM's
          Introductory Information in case of a need to communicate with PICK
          SLAM.
        </li>
      </ul>
      <br />
      <br />
      <h2 className="font-bold">12. Governing Law and Dispute Resolution</h2>
      <br />
      <div className="pl-10">
        <h3 className="font-semibold">1.Governing Law</h3>
        <br />
        <p>
          This Agreement shall be governed by and construed in accordance with
          the laws of Delaware, United States
        </p>
        <br />
        <h3 className="font-semibold">2.Dispute Resolution</h3>
        <br />
        <p>
          Courts and Execution Offices of Delaware, United States shall have the
          exclusive jurisdictional right on settling the disputes arising out of
          this Agreement. In any dispute arising from the performance of this
          contract, the data stored on the Platform or the PICK SLAM system,
          user records and PICK SLAM's commercial books constitute binding and
          conclusive evidence.
        </p>
      </div>
      <br />
      <br />
      <h2 className="font-bold">13. Amendments</h2>
      <br />
      <p className="pl-10">
        PICK SLAM is entitled to unilaterally change or amend the Terms &
        Conditions in order to provide the continuity of the Platform, for
        technical necessities and compliance with the legislation. In this
        regard, PICK SLAM shall notify the User. In this case, the usage of the
        Platform and its Services will be subject to the terms of the updated
        Terms & Conditions.
      </p>
      <br />
      <br />
      <h2 className="font-bold">14. Enforcement</h2>
      <br />
      <p className="pl-10">
        This Agreement shall enter into force at the date of approval of the
        User and shall stay in force until the account of the User is
        terminated.
      </p>
    </div>
  );
};

export default TermsConditions;
