import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import { Pagination } from "swiper";
import "./style.css";
import { Link } from "react-router-dom";

const Home = () => {
  return (
    <div className="">
      <section className="promotion w-full">
        <div className="bg-[#F24822] flex flex-col justify-center items-center">
          <div className="bg-[#2250F2] w-3/6 flex justify-center rounded-b-3xl">
            <img
              src="/images/pickslam-logo.png"
              alt=""
              className="w-32 lg:w-44"
            />
          </div>
          <div className="flex flex-col items-center py-20 text-white">
            <p className="text-xl lg:text-3xl">Experience the</p>
            <p className="font-black text-4xl lg:text-6xl mt-3">
              <span className="mx-1 block text-center lg:inline">
                Revolutionary Fantasy Sports Game!
              </span>
            </p>
            <p className="text-xl lg:text-3xl mt-3">
              Play now in Football Matchups!
            </p>
          </div>
        </div>
        <div className="bg-[#F9BD00] grid grid-cols-12">
          <div className="col-span-12 order-2 xl:order-1 xl:col-span-5 flex justify-center">
            <img src="/images/phone.png" className="w-[500px]" alt="" />
          </div>
          <div className="col-span-12 order-1 xl:order-2 xl:col-span-7 flex justify-center items-center px-5 lg:px-36">
            <div className="field rounded-3xl my-3 flex flex-col items-center p-8 xl:p-16">
              <div className="flex justify-center">
                <span className="flex justify-center items-center text-white text-md lg:text-5xl font-semibold lg:font-black px-5 lg:px-10 py-3 lg:py-6 bg-[#4E8FE3] rounded-l-md">
                  <a
                    href="https://apps.apple.com/app/pickslam/id6445967481"
                    target="blank"
                  >
                    PLAY
                  </a>
                </span>
                <span className="bg-[#2250F2]  rounded-r-md flex justify-center items-center">
                  <img
                    src="/images/pickslam-logo.png"
                    alt=""
                    className="w-[100px] lg:w-[200px]  rounded-r-md"
                  />
                </span>
              </div>
              <div className="mt-5 text-white text-center w-full text-lg lg:text-2xl">
                <p className="mb-5">Pick Your Passion, Win Every Day!</p>
                <p>Unleash the action with Pick Slam's Matchups </p>
                <p>
                  English-French-German-Spanish-Italian-Turkish-Saudi Football
                  Leagues
                </p>
                <p className="mt-5">Daily Matchups, Daily Rewards</p>
              </div>
              <div className="mt-5 text-center">
                <div className="text-3xl md:text-3xl xl:text-3xl 2xl:text-5xl font-semibold text-white">
                  <span>Pick & Win!</span>
                </div>
              </div>
              <div className="flex justify-center items-center mt-5">
                <a
                  href="https://play.google.com/store/apps/details?id=com.performa.pickslam"
                  target="blank"
                  className="w-1/3 mx-1"
                >
                  <img src="/images/play-store.png" alt="" />
                </a>
                <a
                  href="https://apps.apple.com/app/pickslam/id6445967481"
                  target="blank"
                  className="w-1/3 mx-1"
                >
                  <img src="/images/app-store.png" alt="" />
                </a>
              </div>
              <div className="flex justify-center mt-5">
                <a
                  href="https://www.instagram.com/pickslam"
                  target="blank"
                  className="w-1/5 mx-1"
                >
                  <img src="/images/instagram.png" alt="" />
                </a>
                <a
                  href="https://twitter.com/pickslam?s=20"
                  target="blank"
                  className="w-1/5 mx-1"
                >
                  <img src="/images/twitter.png" alt="" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-[#000000] py-5">
        <div className="mt-3 lg:mt-5">
          <Swiper
            slidesPerView={1}
            spaceBetween={10}
            pagination={{
              clickable: true,
            }}
            breakpoints={{
              640: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
              1280: {
                slidesPerView: 4,
                spaceBetween: 20,
              },
            }}
            modules={[Pagination]}
            className="mySwiper"
          >
            <SwiperSlide>
              <img
                src="/images/cardplayer/player1.png"
                alt=""
                className="mx-3 hover:scale-105 ease-in-out duration-300 cursor-pointer"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src="/images/cardplayer/player2.png"
                alt=""
                className="mx-3 hover:scale-105 ease-in-out duration-300 cursor-pointer"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src="/images/cardplayer/player3.png"
                alt=""
                className="mx-3 hover:scale-105 ease-in-out duration-300 cursor-pointer"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src="/images/cardplayer/player4.png"
                alt=""
                className="mx-3 hover:scale-105 ease-in-out duration-300 cursor-pointer"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src="/images/cardplayer/player5.png"
                alt=""
                className="mx-3 hover:scale-105 ease-in-out duration-300 cursor-pointer"
              />
            </SwiperSlide>
          </Swiper>
        </div>

        <div className="mt-3 lg:mt-5">
          <Swiper
            slidesPerView={1}
            spaceBetween={10}
            pagination={{
              clickable: true,
            }}
            breakpoints={{
              640: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
              1280: {
                slidesPerView: 4,
                spaceBetween: 20,
              },
            }}
            modules={[Pagination]}
            className="mySwiper"
          >
            <SwiperSlide>
              <img
                src="/images/cardstatus/status1.png"
                alt=""
                className="mx-3 hover:scale-105 ease-in-out duration-300 cursor-pointer"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src="/images/cardstatus/status2.png"
                alt=""
                className="mx-3 hover:scale-105 ease-in-out duration-300 cursor-pointer"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src="/images/cardstatus/status3.png"
                alt=""
                className="mx-3 hover:scale-105 ease-in-out duration-300 cursor-pointer"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src="/images/cardstatus/status4.png"
                alt=""
                className="mx-3 hover:scale-105 ease-in-out duration-300 cursor-pointer"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src="/images/cardstatus/status5.png"
                alt=""
                className="mx-3 hover:scale-105 ease-in-out duration-300 cursor-pointer"
              />
            </SwiperSlide>
          </Swiper>
        </div>
        <div className="mt-3 lg:mt-5">
          <Swiper
            slidesPerView={1}
            spaceBetween={10}
            pagination={{
              clickable: true,
            }}
            breakpoints={{
              640: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
              1280: {
                slidesPerView: 4,
                spaceBetween: 20,
              },
            }}
            modules={[Pagination]}
            className="mySwiper"
          >
            <SwiperSlide>
              <img
                src="/images/cardpoint/point1.png"
                alt=""
                className="mx-3 hover:scale-105 ease-in-out duration-300 cursor-pointer"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src="/images/cardpoint/point2.png"
                alt=""
                className="mx-3 hover:scale-105 ease-in-out duration-300 cursor-pointer"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src="/images/cardpoint/point3.png"
                alt=""
                className="mx-3 hover:scale-105 ease-in-out duration-300 cursor-pointer"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src="/images/cardpoint/point4.png"
                alt=""
                className="mx-3 hover:scale-105 ease-in-out duration-300 cursor-pointer"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src="/images/cardpoint/point5.png"
                alt=""
                className="mx-3 hover:scale-105 ease-in-out duration-300 cursor-pointer"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src="/images/cardpoint/point6.png"
                alt=""
                className="mx-3 hover:scale-105 ease-in-out duration-300 cursor-pointer"
              />
            </SwiperSlide>
          </Swiper>
        </div>

        <div className="text-center py-5 mt-2 text-white text-2xl lg:text-3xl font-semibold">
          Play Now in Best Football Leagues!
        </div>

        <div className="mt-3 lg:mt-5">
          <Swiper
            slidesPerView={1}
            spaceBetween={10}
            pagination={{
              clickable: true,
            }}
            breakpoints={{
              640: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
              1280: {
                slidesPerView: 4,
                spaceBetween: 20,
              },
            }}
            modules={[Pagination]}
            className="mySwiper"
          >
            <SwiperSlide>
              <img
                src="/images/cardnfl/nfl1.png"
                alt=""
                className="mx-3 hover:scale-105 ease-in-out duration-300 cursor-pointer"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src="/images/cardnfl/nfl2.png"
                alt=""
                className="mx-3 hover:scale-105 ease-in-out duration-300 cursor-pointer"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src="/images/cardnfl/nfl3.png"
                alt=""
                className="mx-3 hover:scale-105 ease-in-out duration-300 cursor-pointer"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src="/images/cardnfl/nfl4.png"
                alt=""
                className="mx-3 hover:scale-105 ease-in-out duration-300 cursor-pointer"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src="/images/cardnfl/nfl5.png"
                alt=""
                className="mx-3 hover:scale-105 ease-in-out duration-300 cursor-pointer"
              />
            </SwiperSlide>
          </Swiper>
        </div>
      </section>

      <section className="how-to-play pattern w-full flex flex-col items-center py-5 bg-[#4E4E4E]">
        <div className="w-3/5 flex flex-col justify-center items-center px-2 py-4 bg-[#1B1E24] text-[#F9BD00] font-black mt-10 rounded-2xl">
          <p className="text-[0.50rem] sm:text-[1rem] lg:text-[1.5rem] 2xl:text-[2.5rem]">
            ULTIMATE FANTASY SPORTS GAME
          </p>
          <p className="text-[1.5rem] lg:text-[3.5rem] 2xl:text-[6rem]">
            HOW TO PLAY?
          </p>
        </div>
        <div className="w-4/5  text-white bg-[#1B1E24] p-3 lg:p-12 rounded-2xl mt-5">
          <h1 className="text-2xl md:text-6xl font-bold text-center mb-5">
            Creating Tickets
          </h1>

          <div className="grid grid-cols-2">
            <div className="col-span-2 lg:col-span-1 order-1 flex justify-center items-center sm:text-left text-center m-3 text-md md:text-xl">
              As soon as you log in, you'll be taken to the electrifying Matchup
              screen, where you'll be selecting your pick from the two top
              players. The key is to pick the player who will earn the most
              Fantasy Points - it's that simple!
            </div>
            <div className="col-span-2 lg:col-span-1 order-2 text-center m-3">
              <img src="/images/cardnfl/nfl5.png" alt="" className="w-full" />
            </div>
          </div>
          <div className="grid grid-cols-2">
            <div className="col-span-2 lg:col-span-1 lg:order-1 order-2 m-3">
              <img
                src="/images/cardpoint/point6.png"
                alt=""
                className="w-full"
              />
            </div>
            <div className="col-span-2 lg:col-span-1 lg:order-2 order-1 flex justify-center items-center sm:text-left text-center m-3 text-md md:text-xl">
              But here's the catch: each ticket must consist of 5 matchup picks,
              so choose wisely! The number of Slam Points (SP) you receive for
              each player pick determines your points you get for your ticket,
              so aim for the stars!
            </div>
          </div>
        </div>

        <div className="w-4/5  text-white bg-[#1B1E24] p-3 lg:p-12 rounded-2xl mt-5">
          <h1 className="text-2xl md:text-6xl font-bold text-center mb-5">
            Match Days
          </h1>

          <div className="grid grid-cols-2">
            <div className="col-span-2 lg:col-span-1 order-1 flex justify-center items-center sm:text-left text-center m-3 text-md md:text-xl">
              No active matchups? No problem! At Pick Slam, we've got you
              covered with daily-based matches that are sure to keep you on the
              edge of your seat. So don't fret, you won't miss out on any of the
              action!
            </div>
            <div className="col-span-2 lg:col-span-1 order-2 text-center m-3">
              <img src="/images/announced.png" alt="" className="w-full" />
            </div>
          </div>
          <div className="grid grid-cols-2">
            <div className="col-span-2 lg:col-span-1 lg:order-1 order-2 m-3">
              <img src="/images/navigation.png" alt="" className="w-full" />
            </div>
            <div className="col-span-2 lg:col-span-1 lg:order-2 order-1 flex justify-center items-center m-3 sm:text-left text-center text-md md:text-xl">
              To check the Match-day status, just head over to the Home Page and
              stay up-to-date with all the latest games. And if you're worried
              about missing out, simply set a reminder and we'll send you a
              notification when the matches are about to begin.
            </div>
          </div>
          <div className="grid grid-cols-2">
            <div className="col-span-2 lg:col-span-1 order-1 flex justify-center items-center m-3 sm:text-left text-center text-md md:text-xl">
              Remember, the cutoff to join a Matchday is always right before the
              last match’s kick-off time. And the best part? You can pick and
              create as many tickets as you want, all day long! So what are you
              waiting for? Get in the game and start winning!
            </div>
            <div className="col-span-2 lg:col-span-1 order-2 text-center m-3">
              <img src="/images/gameon.png" alt="" className="w-full" />
            </div>
          </div>
        </div>
      </section>
      <section className="footer bg-[#F24822] flex justify-center pt-5 text-white">
        <div className="lg:w-1/3">
          <div className="text-white text-2xl md:text-6xl font-black text-center">
            SEE THE LATEST
          </div>
          <div className="text-white text-sm lg:text-lg text-center mt-5  font-semibold">
            To stay on top of your game, keep an eye on the game. Follow us on
            social media for the latest chatter and sneak peeks on what the team
            is working on. Don’t be a stranger and join the conversation.
          </div>
          <div className="flex justify-center mt-3">
            <a
              href="https://www.instagram.com/pickslam"
              target="blank"
              className="mx-2"
            >
              <img src="/images/instagram-footer.svg" alt="" />
            </a>
            <a
              href="https://twitter.com/pickslam?s=20"
              target="blank"
              className="mx-2"
            >
              <img src="/images/twitter-footer.svg" alt="" />
            </a>
          </div>

          <div className="bg-[#2250F2] py-5 px-2 rounded-t-2xl text-center mt-3">
            <div className="grid grid-cols-3 mb-2">
              <p className="xl:col-span-1 col-span-3">
                <Link to="/cookies" target="_blank" className="underline">
                  Cookies Policy
                </Link>
              </p>
              <p className="xl:col-span-1 col-span-3">
                <Link
                  to="/privacy-policy"
                  target="_blank"
                  className="underline"
                >
                  Privacy Policy
                </Link>
              </p>
              <p className="xl:col-span-1 col-span-3">
                <Link
                  to="/terms-conditions"
                  target="_blank"
                  className="underline"
                >
                  Terms Conditions
                </Link>
              </p>
            </div>

            <p>Fantazzie Inc.</p>
            <p>All rights reserved.</p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Home;
