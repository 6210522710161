import React from "react";

const Cookies = () => {
  return (
    <div className="xl:px-80 lg:px-40 px-5 py-20">
      <div className="">
        <h1 className="font-bold text-center">COOKIE POLICY</h1>
        <br />
        <p>
          Operated by Fantazzie Inc (hereinafter “Pick Slam” or “we”),
          headquartered at 48W 48th Street, Suite :1204, New York, NY-10036,
          United States; and visitors and users (hereinafter collectively
          referred to as “Individuals” or “you”) on our website named
          www.pickslam.com (hereinafter referred to as “Website”) or the mobile
          application named Pick Slam (hereinafter referred to as “Mobile
          Application”). ) on the condition of observing the rights of privacy
          and protection of personal data; in order to improve their experience
          and enable them to benefit from the site in the most efficient way;
          personal data is collected, processed and internet cookies are used.
          This Cookie Usage Policy (Policy) explains to all Website and Mobile
          Application visitors and/or users which type of cookies are used and
          under what conditions.
        </p>
      </div>
      <br />
      <br />
      <div className="">
        <h1 className="font-semibold">What is a cookie?</h1>
        <br />
        <p>
          Cookies are small text files that allow certain information about
          Individuals to be stored on the terminal devices of Individuals when a
          website is visited. In these files, information about the Internet
          visits of the Individuals is stored and no information is collected
          about the files stored on mobile devices or computers. Cookies allow a
          site to work more efficiently, as well as to provide personalized
          pages in order to provide a more convenient and faster visit
          experience to the personal needs of individuals.
        </p>
      </div>
      <br />
      <br />
      <div className="">
        <h1 className="font-semibold">Cookies by Duration</h1>
        <br />
        <p>
          Cookies are divided into two as temporary and permanent cookies
          according to their duration. Temporary cookies are also called session
          cookies and are used to ensure the continuity of the session, and
          session cookies are also deleted when individuals log out. Persistent
          cookies, on the other hand, are created when the Website is visited
          and remain until Individuals delete or expire. Persistent cookies can
          also be used to ensure that individuals do not re-enter their login
          information each time they log into their accounts on the Website.
        </p>
        <br />
        <p>Cookies by Purpose of Use</p>
      </div>
      <br />
      <br />
      <div className="">
        <h1 className="font-semibold">Strictly Necessary Cookies</h1>
        <br />
        <p>
          (Mandatory Cookies) These are cookies that are necessary for the
          Website or Mobile Application to work. Such cookies are used for
          purposes such as remembering privacy preferences, not losing
          information about the session of individuals, filling out forms. If
          mandatory cookies are blocked, some parts of the Website or Mobile
          Application will not work. These cookies are used for purposes such as
          security and authentication and are not used for any marketing
          purpose.
        </p>
      </div>
      <br />
      <br />
      <div className="">
        <h1 className="font-semibold">Functional Cookies</h1>
        <br />
        <p>
          These are cookies used for personalization and remembering preferences
          used on the Website or Mobile Application. Not allowing the use of
          such cookies may result in not using a feature specific to Individuals
          and prevents Individuals from remembering their preferences. Cookies
          Necessary for Performance and Analysis are cookies that help improve
          the Website or Mobile Application. This type of cookies collects
          information about the use of the site by individuals, is used to
          control whether the site is working properly and to detect errors
          received. Targeting and Advertising Cookies With cookies for
          advertising and marketing purposes, it is aimed to track the online
          movements of individuals on the internet, to determine their personal
          interests and to display advertisements to individuals on the internet
          for these interests.
        </p>
      </div>
      <br />
      <br />
      <div className="">
        <h1 className="font-semibold">Purpose of Use of Cookies</h1>
        <br />
        <p>
          The main purposes of using cookies on the Website and Mobile
          Application can be listed as follows:
        </p>
        <br />
        <ul className="list-disc pl-4">
          <li>
            To increase the functionality and performance of the Website and
            Mobile Application,
          </li>
          <li> To provide secure and functional login of individuals,</li>
          <li>To improve the usage experience of individuals,</li>
          <li>
            To improve the Website and Mobile Application and to offer new
            features on it and to personalize the offered features according to
            the preferences of the Individuals,
          </li>
          <li>
            Ensuring the safety of individuals and reporting the problems that
            need to be resolved,and
          </li>
          <li>
            To ensure the legal and commercial security of the Website, Mobile
            Application and PICK SLAM.
          </li>
        </ul>
      </div>
      <br />
      <br />
      <div className="">
        <h1 className="font-semibold">
          How Do We Collect Your Data Through Cookies?
        </h1>
        <br />
        <p>
          When you visit the Website or Mobile Application and log in to the
          Website or Mobile Application, we automatically collect and process
          your data via cookies by storing small text files on your device or
          network server via browsers. These collected data are device specific
          and can be deleted by Individuals at any time and access to
          information can be closed. However, in this case, it may be the case
          that the Website and Mobile Application may not be able to function
          fully and not be able to benefit from all its features.
        </p>
      </div>
    </div>
  );
};

export default Cookies;
