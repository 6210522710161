import './App.css';
import React, { Fragment } from 'react';
import 'swiper/css';
import 'swiper/css/free-mode';
import Home from './pages/home';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Cookies from './components/cookies';
import PrivacyPolicy from './components/privacy-policy';
import TermsConditions from './components/terms-conditions';
import FAQPage from './components/faq';
import ValidateUserPage from './pages/validate-user';
import ResetPasswordForm from './pages/reset-password';
import DownloadApp from './pages/download-app';

function App() {
  return (
    <Fragment>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/cookies" element={<Cookies />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-conditions" element={<TermsConditions />} />
          <Route path="/help" element={<FAQPage />} />
          <Route path="/validate-user" element={<ValidateUserPage />} />
          <Route path="/reset-password" element={<ResetPasswordForm />} />
          <Route path="/app" element={<DownloadApp />} />
        </Routes>
      </BrowserRouter>
    </Fragment>
  );
}

export default App;
