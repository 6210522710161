import React, { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Modal from 'react-modal';
import { ForgotPasswordConfirm } from '../../services/auth';
import toast, { Toaster } from 'react-hot-toast';
import * as yup from 'yup';

const ResetPasswordForm = () => {
  const navigate = useNavigate();

  const [verificationCode, setVerificationCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [errors, setErrors] = useState({});
  const [modalIsOpen, setIsOpen] = useState(false);

  const widthScreen = window.innerWidth;

  const sizeChange = () => {
    if (widthScreen > 1600) {
      return '30%';
    } else if (widthScreen > 1200) {
      return '40%';
    } else if (widthScreen > 800) {
      return '60%';
    } else if (widthScreen > 200) {
      return '80%';
    }
  };

  const customStyles = {
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    },
    content: {
      width: sizeChange(),
      // height: "750px",
      top: '350px',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      borderRadius: '10px',
      overflowY: 'hidden',
      background: '#33373E',
    },
  };

  const params = useSearchParams();

  const Id = params[0].get('Id');

  const validationSchema = yup.object().shape({
    verificationCode: yup.number().required('Verification code is required.'),
    newPassword: yup
      .string()
      .matches(
        /^(?=.*[a-zşŞıİçÇğĞüÜöÖ])(?=.*[A-ZŞİĞÜÇ])(?=.*\d)[a-zA-ZşŞıİçÇğĞüÜöÖ\d!@#$%^&*()\-_=+[\]{};:'",<.>/?\\|]{8,16}$/,
        'Your password must contain at least 8 characters, at least 1 lowercase letter, at least 1 uppercase letter, and at least 1 number.'
      )
      .required('New password is required.'),
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Form verilerini validasyon şemasına göre doğrulama
      await validationSchema.validate({ verificationCode, newPassword }, { abortEarly: false });

      // Validasyon başarılıysa API isteğini gerçekleştir
      await ForgotPasswordConfirm({
        ForgotPasswordCode: verificationCode,
        Password: newPassword,
        ForgotPasswordId: Id,
      }).then((res) => {
        // setIsOpen(true);

        if (res?.data?.Result?.ResultCode === 0) {
          setIsOpen(true);
        } else if (res?.data?.Result?.ResultCode === -1) {
          const notify = () => toast.error(res?.data?.Result?.ResultMessage, 'top-center');

          notify();
        }
      });

      // Navigate("/login");
    } catch (error) {
      // Hata durumunu işleme
      if (error.name === 'ValidationError') {
        // Yup validasyon hatası durumunda hataları kullanıcıya göstermek için errors state'ini güncelle
        const newErrors = {};
        error.inner.forEach((err) => {
          newErrors[err.path] = err.message;
        });
        setErrors(newErrors);
      } else {
        toast.error(error.message, { position: 'top-right' });
      }
    }
  };

  return (
    <>
      <div className="bg-black w-full min-h-screen flex flex-col justify-center sm:justify-end items-center">
        <div className="w-4/12 sm:w-3/12 md:w-2/12 lg:w-1/12 m-5">
          <img src="https://imagedelivery.net/rH_1m6pdkqTZ9d23is_iWQ/3d38eafe-a968-4c7c-3214-cab59c54b700/public" alt="" />
        </div>
        <p className="w-full text-center text-white text-xl font-semibold mb-2">Forgot Password</p>

        <form className="shadow-md rounded px-8 pt-6 pb-8 mb-4 w-full sm:w-9/12 md:w-6/12 lg:w-4/12" onSubmit={handleSubmit}>
          <div className="mb-4 flex flex-col">
            <label className="text-white text-sm font-bold mb-2" htmlFor="verificationCode">
              Verification code
            </label>
            <input
              className={`rounded-md py-4 px-3 bg-[#33373E] text-white leading-tight focus:outline-none focus:shadow-outline ${
                errors['verificationCode'] ? 'border-red-500' : ''
              }`}
              id="verificationCode"
              type="text"
              placeholder="Verification code..."
              value={verificationCode}
              onChange={(e) => {
                setVerificationCode(e.target.value);
                // Input değiştiğinde hatayı temizle
                setErrors({ ...errors, verificationCode: '' });
              }}
            />
            {errors['verificationCode'] && <p className="text-red-500 text-xs mt-1">{errors['verificationCode']}</p>}
          </div>
          <div className="mb-1 flex flex-col">
            <label className="text-white text-sm font-bold mb-2" htmlFor="newPassword">
              New password
            </label>
            <input
              className={`rounded-md py-4 px-3 bg-[#33373E] text-white leading-tight focus:outline-none focus:shadow-outline ${
                errors['newPassword'] ? 'border-red-500' : ''
              }`}
              id="newPassword"
              type="password"
              placeholder="New password..."
              value={newPassword}
              onChange={(e) => {
                setNewPassword(e.target.value);
                // Input değiştiğinde hatayı temizle
                setErrors({ ...errors, newPassword: '' });
              }}
            />
            {errors['newPassword'] && <p className="text-red-500 text-xs mt-1">{errors['newPassword']}</p>}
          </div>
          <p className="text-[#C4C4C4] text-xs mb-4">8 characters minimum</p>

          <div className="flex items-center justify-center">
            <button className="w-full py-4 px-2 bg-[#C4C4C4] text-black text-sm rounded-md font-semibold" type="submit">
              Change My Password
            </button>
          </div>
        </form>

        <div className=" w-full sm:w-9/12 md:w-7/12 lg:w-4/12">
          <img src="https://imagedelivery.net/rH_1m6pdkqTZ9d23is_iWQ/6c896901-1f25-4421-66e0-50fc63fc9600/public" alt="" />
        </div>
      </div>

      <Modal
        isOpen={modalIsOpen}
        // onAfterOpen={afterOpenModal}
        // onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
        ariaHideApp={false}
      >
        <div className="w-full flex justify-end mb-5">
          <button onClick={() => setIsOpen(false)}>
            <img src="/images/dismiss.png" alt="" className="w-4" />
          </button>
        </div>
        <div
          className="scroll-styled-hidden text-white flex flex-col items-center justify-start"
          style={{ height: '280px', overflowY: 'auto' }}
        >
          <p className="mb-5 font-semibold">Password Changed</p>
          <div className="flex justify-center mb-5">
            <img src="/images/succces-tick.png" alt="" className="w-4/12" />
          </div>
          <p className="mb-5 text-center">You password is changed successfully.</p>
          <div className="flex items-center justify-center w-full">
            <button
              onClick={() => {
                setIsOpen(false);
                navigate('/');
              }}
              className="w-full py-4 px-2 bg-[#2250F2] text-white text-md rounded-lg font-semibold"
              type="button"
            >
              Okay
            </button>
          </div>
        </div>
      </Modal>

      <Toaster />
    </>
  );
};

export default ResetPasswordForm;
