import React, { useEffect, useState } from 'react';
import './faq.css';

const FAQPage = () => {
  const url = `${process.env.REACT_APP_API_URL}s/api/QuestionAnswer/GetCategoryQuestionAnswers`;
  const [questions, setQuestions] = useState();

  useEffect(() => {
    fetch(url)
      .then((response) => response.json())
      .then((data) => setQuestions(data.ReturnObject));
  }, [url]);

  return (
    <div className="bg-black flex justify-center">
      <div className="bg-black tabs w-[600px] mt-5">
        {questions?.map((e, index) => {
          const bgColor = index % 2 === 0 ? 'border-[#F24822] bg-[#F24822]' : 'border-[#2250F2] bg-[#2250F2]';

          return (
            <div className="mb-8" id={index}>
              <p className="font-bold text-[22px] text-white">{e.Name}</p>
              {e.Questions?.map((item, index) => {
                const theObj = { __html: item.Answer };
                return (
                  <div className={`tab w-[400px] border-2 ${bgColor} rounded-lg mb-3`}>
                    <input type="checkbox" className="hidden" id={item.Subject} />
                    <label className={`tab-label text-white px-5 py-2 ${bgColor}`} htmlFor={item.Subject}>
                      {item.Subject}
                    </label>
                    <div className="tab-content bg-black text-white px-4 py-4 rounded-b-lg">
                      <div dangerouslySetInnerHTML={theObj} />
                    </div>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default FAQPage;
