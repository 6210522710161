import { NavLink, useSearchParams } from "react-router-dom";
import { EmailVerification } from "../../services/auth";
import React, { useState, useEffect } from "react";

const ValidateUserPage = () => {
  const [status, setStatus] = useState("loading"); // Initialize status as "loading"
  const params = useSearchParams();
  const Id = params && params[0].get("id");

  const ValidateEmail = async () => {
    try {
      let { data } = await EmailVerification({ id: Id });
      if (data?.Result?.ResultCode === -1) {
        throw new Error("");
      }
      setStatus("success"); // If the promise resolves successfully, set status as "success"
    } catch (err) {
      setStatus("error"); // If an error is thrown, set status as "error"
    }
  };

  useEffect(() => {
    ValidateEmail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderStatus = () => {
    switch (status) {
      case "loading":
        return (
          <p className="text-white font-bold text-base text-center lg:text-2xl">
            Your email address is being verified...
          </p>
        );
      case "success":
        return (
          <div className="flex flex-col justify-center items-center gap-y-3 text-white font-bold text-base text-center lg:text-2xl">
            <img
              src="/images/email-verification/approved.png"
              className="w-16 h-16 lg:w-20 lg:h-20"
              alt="approve"
            />
            <p>Congratulations!</p>
            <p> Your email address has been successfully verified.</p>
            <p>
              {" "}
              You now have full access to all the features and benefits of Pick
              Slam!
            </p>
          </div>
        );
      case "error":
        return (
          <div className="flex flex-col justify-center items-center gap-y-3 text-white font-bold text-base text-center lg:text-2xl">
            <img
              src="/images/email-verification/failed.png"
              className="w-16 h-16 lg:w-20 lg:h-20"
              alt="approve"
            />
            <p>
              Oops! It seems that the verification link has expired or is
              invalid.
            </p>
            <p> Please try again later.</p>
          </div>
        );
      default:
        return "";
    }
  };

  return (
    <>
      <div className="bg-black h-screen flex justify-center items-center">
        <div className="flex w-full h-screen">
          <div className="flex-1 flex flex-col justify-center items-center space-y-4 px-4">
            <img
              src="/images/email-verification/verification-logo.png"
              className="w-[180px] h-[90px]"
              alt="logo"
            />
            <div className="rounded-lg bg-[#1b1e24] p-5 max-w-[600px]">
              {renderStatus()}
              <div className="flex w-full justify-center items-center mt-2">
                <NavLink
                  className=" text-white underline underline-offset-4"
                  to="/"
                >
                  Go to homepage
                </NavLink>
              </div>
            </div>
          </div>
          <div className="w-0 lg:w-[400px] h-full">
            <img
              src="/images/email-verification/right.png"
              className="h-full w-full object-cover"
              alt="logo"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ValidateUserPage;
