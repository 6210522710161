import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="xl:px-80 lg:px-40 px-5 py-20">
      <div className="">
        <h1 className="font-bold text-center">PRIVACY POLICY</h1>
        <br />
        <p>
          We are Fantazzie Inc (“<strong>Pick Slam</strong>”), with our principal office at 48W 48th Street, Suite :1204, New
          York, NY-10036, United States of America, providing our users the Pick Slam Platform through our website&nbsp;
          <a href="https://pickslam.com/" className="underline decoration-sky-500 text-sky-500" target="blank">
            www.pickslam.com
          </a>
          &nbsp;and mobile application Pick Slam (<strong>“Platform</strong>”)
        </p>
        <br />
        <br />
        <p>
          When you use subscribe and use our Platform, we collect and process some of your personal data as described in detail
          below.
        </p>
        <br />
        <h2 className="font-semibold">How Do We Collect Your Personal Data?</h2>
        <br />
        <p>
          We collect your personal data when you self-register yourself to the platform and submit your personal data for signing
          up by completing the entry fields we provide
        </p>
        <p>
          We collect your personal data listed under “During Session” below automatically through Platform during your session.
        </p>
        <br />
        <br />
        <h1 className="font-bold">PERSONAL DATA WE COLLECT & PROCESS DURING SIGN UP</h1>
        <br />
        <ul className="pl-4">
          <li>➢ E-mail</li>
          <li>➢ Password</li>
          <li>➢ Name of the user’s in-game team</li>
          <li>➢ Favorite Player/Team</li>
        </ul>
      </div>
      <br />
      <br />
      <div>
        <h2 className="font-semibold">Purposes of Processing Personal Data Collected During Sign Up</h2>
        <br />
        <div className="w-full overflow-x-auto">
          <table className="table-auto  w-full border border-1">
            <thead>
              <tr className="text-center border border-1 border-black bg-[#d0cece]">
                <th className="border border-1  border-black p-2"></th>
                <th className="border border-1  border-black p-2">Identification</th>
                <th className="border border-1  border-black p-2">Accoun activation</th>
                <th className="border border-1  border-black p-2">Enabling secure acces/transaction</th>
                <th className="border border-1  border-black p-2">Enhacing user experience</th>
                <th className="border border-1  border-black p-2">Necessary communication</th>
                <th className="border border-1  border-black p-2">Sending info & document</th>
                <th className="border border-1  border-black p-2">Marketing(subject to your explicit consent)</th>
                <th className="border border-1  border-black p-2">Taking adequate technical measures</th>
              </tr>
            </thead>
            <tbody>
              <tr className="border border-1">
                <td className="border border-1 p-2  border-black bg-[#d0cece] font-semibold">Email</td>
                <td className="border border-1 p-2  border-black bg-[#fbe4d5]"></td>
                <td className="border border-1 p-2  border-black bg-[#fbe4d5]"></td>
                <td className="border border-1 p-2  border-black bg-[#fbe4d5]"></td>
                <td className="border border-1 p-2  border-black bg-[#fbe4d5]"></td>
                <td className="border border-1 p-2  border-black bg-[#fbe4d5]"></td>
                <td className="border border-1 p-2  border-black bg-[#fbe4d5]"></td>
                <td className="border border-1 p-2  border-black bg-[#fbe4d5]"></td>
                <td className="border border-1 p-2  border-black bg-[#fbe4d5]"></td>
              </tr>
              <tr className="border border-1">
                <td className="border border-1 p-2 border-black bg-[#d0cece] font-semibold">Password</td>
                <td className="border border-1 p-2  border-black bg-[#fbe4d5]"></td>
                <td className="border border-1 p-2  border-black bg-[#fbe4d5]"></td>
                <td className="border border-1 p-2  border-black bg-[#fbe4d5]"></td>
                <td className="border border-1 p-2  border-black bg-[#fbe4d5]"></td>
                <td className="border border-1 p-2  border-black bg-[#fbe4d5]"></td>
                <td className="border border-1 p-2  border-black bg-[#fbe4d5]"></td>
                <td className="border border-1 p-2  border-black bg-[#fbe4d5]"></td>
                <td className="border border-1 p-2  border-black bg-[#fbe4d5]"></td>
              </tr>
              <tr className="border border-1">
                <td className="border border-1 p-2 border-black bg-[#d0cece] font-semibold">Name of the user’s ingame team</td>
                <td className="border border-1 p-2  border-black bg-[#fbe4d5]"></td>
                <td className="border border-1 p-2  border-black bg-[#fbe4d5]"></td>
                <td className="border border-1 p-2  border-black bg-[#fbe4d5]"></td>
                <td className="border border-1 p-2  border-black bg-[#fbe4d5]"></td>
                <td className="border border-1 p-2  border-black bg-[#fbe4d5]"></td>
                <td className="border border-1 p-2  border-black bg-[#fbe4d5]"></td>
                <td className="border border-1 p-2  border-black bg-[#fbe4d5]"></td>
                <td className="border border-1 p-2  border-black bg-[#fbe4d5]"></td>
              </tr>
              <tr className="border border-1">
                <td className="border border-1 p-2 border-black bg-[#d0cece] font-semibold">Favorite Player/ Team</td>
                <td className="border border-1 p-2  border-black bg-[#fbe4d5]"></td>
                <td className="border border-1 p-2  border-black bg-[#fbe4d5]"></td>
                <td className="border border-1 p-2  border-black bg-[#fbe4d5]"></td>
                <td className="border border-1 p-2  border-black bg-[#fbe4d5]"></td>
                <td className="border border-1 p-2  border-black bg-[#fbe4d5]"></td>
                <td className="border border-1 p-2  border-black bg-[#fbe4d5]"></td>
                <td className="border border-1 p-2  border-black bg-[#fbe4d5]"></td>
                <td className="border border-1 p-2  border-black bg-[#fbe4d5]"></td>
              </tr>
            </tbody>
          </table>
        </div>
        <br />
        <p>
          We collect your e-mail to activate your account and process it for the purposes of enabling secure access to your
          account, to send you the documents regarding the transactions you make while using the Platform and running the
          communications regarding the usage of Platform when necessary. We also use your e-mail address to ensure the security of
          your transactions. Only if you explicitly consent for receiving marketing e-mails, we will use your e-mail information
          to send marketing e-mails for the purpose of informing you on the promotions or products/services developed and/or
          provided by PICK SLAM.
        </p>
        <br />
        <p>
          We request you to create a password to provide you with a secure & operational session in the Platform which also
          satisfies our obligation to implement adequate technical measures required by personal data protection laws.
        </p>
        <br />
        <p>We process the name of your in-game team for the purpose of providing you and identification in the game.</p>
        <br />
        <p>If you prefer to submit your Favorite Player/Team info we only process it to enhance user experience.</p>
        <br />
        <br />
        <h2 className="font-semibold">Legal Grounds of Collecting Personal Data During Sign Up</h2>
        <br />
        <div className="w-full overflow-x-auto">
          <table className="table-auto border border-1">
            <thead>
              <tr className="text-center border border-1 border-black bg-[#d0cece]">
                <th className="border border-1  border-black p-2"></th>
                <th className="border border-1  border-black p-2">Compliance with laws</th>
                <th className="border border-1  border-black p-2">Compliance with our legal obligations</th>
                <th className="border border-1  border-black p-2">Our legitimate interests</th>
              </tr>
            </thead>
            <tbody>
              <tr className="border border-1">
                <td className="border border-1 p-2  border-black bg-[#d0cece] font-semibold">Email</td>
                <td className="border border-1 p-2  border-black bg-[#fbe4d5]"></td>
                <td className="border border-1 p-2  border-black bg-[#fbe4d5]"></td>
                <td className="border border-1 p-2  border-black bg-[#fbe4d5]"></td>
              </tr>
              <tr className="border border-1">
                <td className="border border-1 p-2 border-black bg-[#d0cece] font-semibold">Password</td>
                <td className="border border-1 p-2  border-black bg-[#fbe4d5]"></td>
                <td className="border border-1 p-2  border-black bg-[#fbe4d5]"></td>
                <td className="border border-1 p-2  border-black bg-[#fbe4d5]"></td>
              </tr>
              <tr className="border border-1">
                <td className="border border-1 p-2 border-black bg-[#d0cece] font-semibold">Name of the user’s ingame team</td>
                <td className="border border-1 p-2  border-black bg-[#fbe4d5]"></td>
                <td className="border border-1 p-2  border-black bg-[#fbe4d5]"></td>
                <td className="border border-1 p-2  border-black bg-[#fbe4d5]"></td>
              </tr>
              <tr className="border border-1">
                <td className="border border-1 p-2 border-black bg-[#d0cece] font-semibold">Favorite Player/ Team</td>
                <td className="border border-1 p-2  border-black bg-[#fbe4d5]"></td>
                <td className="border border-1 p-2  border-black bg-[#fbe4d5]"></td>
                <td className="border border-1 p-2  border-black bg-[#fbe4d5]"></td>
              </tr>
            </tbody>
          </table>
        </div>
        <br />
        <p>
          As per our contractual obligations to you and our legitimate interest in user satisfaction, we are required to provide
          you and other users, with secure, operational and user-friendly access to our Platform. Furthermore, we are required by
          personal data protection laws to provide you with a secure access. For these reasons, we collect the personal data
          listed above. Only if you explicitly consent for receiving marketing e-mails, we use the e-mail data for sending you
          marketing emails as explained above.
        </p>
        <br />
        <br />
        <h1 className="font-bold">PERSONAL DATA WE COLLECT & PROCESS DURING YOUR SESSION</h1>
        <br />
        <ul className="pl-4">
          <li>➢ Login & Logout Time</li>
          <li>➢ IP Address</li>
          <li>➢ Profile Photo</li>
          <li>➢ Favorite Player/Team</li>
          <li>➢ Name & Surname</li>
          <li>➢ Phone Number</li>
        </ul>
        <br />
        <br />
        <h2 className="font-semibold">Purposes of Processing Personal Data Collected During Your Session</h2>
        <br />
        <div className="w-full overflow-x-auto">
          <table className="table-auto w-full border border-1">
            <thead>
              <tr className="text-center border border-1 border-black bg-[#d0cece]">
                <th className="border border-1  border-black p-2"></th>
                <th className="border border-1  border-black p-2">Providing secure & operational session</th>
                <th className="border border-1  border-black p-2">Taking adequate technical measures</th>
                <th className="border border-1  border-black p-2">Enabling secure transaction</th>
                <th className="border border-1  border-black p-2">Identification</th>
                <th className="border border-1  border-black p-2">Necessary communication</th>
                <th className="border border-1  border-black p-2">Enhacing user experience</th>
                <th className="border border-1  border-black p-2">User support</th>
                <th className="border border-1  border-black p-2">Marketing(subject to your explicit consent) </th>
              </tr>
            </thead>
            <tbody>
              <tr className="border border-1">
                <td className="border border-1 p-2  border-black bg-[#d0cece] font-semibold">Login & Logout Time</td>
                <td className="border border-1 p-2  border-black bg-[#fbe4d5]"></td>
                <td className="border border-1 p-2  border-black bg-[#fbe4d5]"></td>
                <td className="border border-1 p-2  border-black bg-[#fbe4d5]"></td>
                <td className="border border-1 p-2  border-black bg-[#fbe4d5]"></td>
                <td className="border border-1 p-2  border-black bg-[#fbe4d5]"></td>
                <td className="border border-1 p-2  border-black bg-[#fbe4d5]"></td>
                <td className="border border-1 p-2  border-black bg-[#fbe4d5]"></td>
                <td className="border border-1 p-2  border-black bg-[#fbe4d5]"></td>
              </tr>
              <tr className="border border-1">
                <td className="border border-1 p-2 border-black bg-[#d0cece] font-semibold">IP Address</td>
                <td className="border border-1 p-2  border-black bg-[#fbe4d5]"></td>
                <td className="border border-1 p-2  border-black bg-[#fbe4d5]"></td>
                <td className="border border-1 p-2  border-black bg-[#fbe4d5]"></td>
                <td className="border border-1 p-2  border-black bg-[#fbe4d5]"></td>
                <td className="border border-1 p-2  border-black bg-[#fbe4d5]"></td>
                <td className="border border-1 p-2  border-black bg-[#fbe4d5]"></td>
                <td className="border border-1 p-2  border-black bg-[#fbe4d5]"></td>
                <td className="border border-1 p-2  border-black bg-[#fbe4d5]"></td>
              </tr>
              <tr className="border border-1">
                <td className="border border-1 p-2 border-black bg-[#d0cece] font-semibold">
                  Device/Browser/ Application Provider
                </td>
                <td className="border border-1 p-2  border-black bg-[#fbe4d5]"></td>
                <td className="border border-1 p-2  border-black bg-[#fbe4d5]"></td>
                <td className="border border-1 p-2  border-black bg-[#fbe4d5]"></td>
                <td className="border border-1 p-2  border-black bg-[#fbe4d5]"></td>
                <td className="border border-1 p-2  border-black bg-[#fbe4d5]"></td>
                <td className="border border-1 p-2  border-black bg-[#fbe4d5]"></td>
                <td className="border border-1 p-2  border-black bg-[#fbe4d5]"></td>
                <td className="border border-1 p-2  border-black bg-[#fbe4d5]"></td>
              </tr>
              <tr className="border border-1">
                <td className="border border-1 p-2 border-black bg-[#d0cece] font-semibold">Profile Photo</td>
                <td className="border border-1 p-2  border-black bg-[#fbe4d5]"></td>
                <td className="border border-1 p-2  border-black bg-[#fbe4d5]"></td>
                <td className="border border-1 p-2  border-black bg-[#fbe4d5]"></td>
                <td className="border border-1 p-2  border-black bg-[#fbe4d5]"></td>
                <td className="border border-1 p-2  border-black bg-[#fbe4d5]"></td>
                <td className="border border-1 p-2  border-black bg-[#fbe4d5]"></td>
                <td className="border border-1 p-2  border-black bg-[#fbe4d5]"></td>
                <td className="border border-1 p-2  border-black bg-[#fbe4d5]"></td>
              </tr>
              <tr className="border border-1">
                <td className="border border-1 p-2 border-black bg-[#d0cece] font-semibold">Name & Surname</td>
                <td className="border border-1 p-2  border-black bg-[#fbe4d5]"></td>
                <td className="border border-1 p-2  border-black bg-[#fbe4d5]"></td>
                <td className="border border-1 p-2  border-black bg-[#fbe4d5]"></td>
                <td className="border border-1 p-2  border-black bg-[#fbe4d5]"></td>
                <td className="border border-1 p-2  border-black bg-[#fbe4d5]"></td>
                <td className="border border-1 p-2  border-black bg-[#fbe4d5]"></td>
                <td className="border border-1 p-2  border-black bg-[#fbe4d5]"></td>
                <td className="border border-1 p-2  border-black bg-[#fbe4d5]"></td>
              </tr>
              <tr className="border border-1">
                <td className="border border-1 p-2 border-black bg-[#d0cece] font-semibold">Phone Number</td>
                <td className="border border-1 p-2  border-black bg-[#fbe4d5]"></td>
                <td className="border border-1 p-2  border-black bg-[#fbe4d5]"></td>
                <td className="border border-1 p-2  border-black bg-[#fbe4d5]"></td>
                <td className="border border-1 p-2  border-black bg-[#fbe4d5]"></td>
                <td className="border border-1 p-2  border-black bg-[#fbe4d5]"></td>
                <td className="border border-1 p-2  border-black bg-[#fbe4d5]"></td>
                <td className="border border-1 p-2  border-black bg-[#fbe4d5]"></td>
                <td className="border border-1 p-2  border-black bg-[#fbe4d5]"></td>
              </tr>
            </tbody>
          </table>
        </div>
        <br />
        <p>
          We collect and process your log in/log out time, IP address and device/browser/application provider data for the
          purposes of providing you with a secure & operational session on the Platform and to protect your account and our
          database from unauthorized access, which is also a legal requirement under personal data privacy laws. As mentioned in
          the table above, we also use some of this personal data to provide user support when necessary.
        </p>
        <br />
        <p>
          If you prefer to submit your Favorite Player/Team info and profile picture we only process it to enhance user
          experience.
        </p>
        <br />
        <p>
          If you prefer to submit your name & surname information we process these data for the purpose of identifying you in the
          Platform and providing you with secure transactions in the Platform.
        </p>
        <br />
        <p>
          If you prefer to submit your phone number information, we process it for the purposes of providing you with user support
          and running the communications regarding the usage of Platform when necessary. We also use your phone number to ensure
          the security of your transactions. Only if you explicitly consent for receiving marketing messages, we will use your
          phone number information to send marketing messages for the purpose of informing you on the promotions or
          products/services developed and/or provided by PICK SLAM.
        </p>
      </div>
      <br />
      <br />
      <div className="w-full overflow-x-auto">
        <table className="table-auto  w-full border border-1">
          <thead>
            <tr className="text-center border border-1 border-black bg-[#d0cece]">
              <th className="border border-1  border-black p-2"></th>
              <th className="border border-1  border-black p-2">Compliance with laws</th>
              <th className="border border-1  border-black p-2">Compliance with our legal obligations</th>
              <th className="border border-1  border-black p-2">Our legitimate interests</th>
            </tr>
          </thead>
          <tbody>
            <tr className="border border-1">
              <td className="border border-1 p-2  border-black bg-[#d0cece] font-semibold">Login & Logout Time</td>
              <td className="border border-1 p-2  border-black bg-[#fbe4d5]"></td>
              <td className="border border-1 p-2  border-black bg-[#fbe4d5]"></td>
              <td className="border border-1 p-2  border-black bg-[#fbe4d5]"></td>
            </tr>
            <tr className="border border-1">
              <td className="border border-1 p-2 border-black bg-[#d0cece] font-semibold">IP Address</td>
              <td className="border border-1 p-2  border-black bg-[#fbe4d5]"></td>
              <td className="border border-1 p-2  border-black bg-[#fbe4d5]"></td>
              <td className="border border-1 p-2  border-black bg-[#fbe4d5]"></td>
            </tr>
            <tr className="border border-1">
              <td className="border border-1 p-2 border-black bg-[#d0cece] font-semibold">Device/Browser/Application Provider</td>
              <td className="border border-1 p-2  border-black bg-[#fbe4d5]"></td>
              <td className="border border-1 p-2  border-black bg-[#fbe4d5]"></td>
              <td className="border border-1 p-2  border-black bg-[#fbe4d5]"></td>
            </tr>
            <tr className="border border-1">
              <td className="border border-1 p-2 border-black bg-[#d0cece] font-semibold">Profile Photo</td>
              <td className="border border-1 p-2  border-black bg-[#fbe4d5]"></td>
              <td className="border border-1 p-2  border-black bg-[#fbe4d5]"></td>
              <td className="border border-1 p-2  border-black bg-[#fbe4d5]"></td>
            </tr>
            <tr className="border border-1">
              <td className="border border-1 p-2 border-black bg-[#d0cece] font-semibold">Name & Surname</td>
              <td className="border border-1 p-2  border-black bg-[#fbe4d5]"></td>
              <td className="border border-1 p-2  border-black bg-[#fbe4d5]"></td>
              <td className="border border-1 p-2  border-black bg-[#fbe4d5]"></td>
            </tr>
            <tr className="border border-1">
              <td className="border border-1 p-2 border-black bg-[#d0cece] font-semibold">Phone Number</td>
              <td className="border border-1 p-2  border-black bg-[#fbe4d5]"></td>
              <td className="border border-1 p-2  border-black bg-[#fbe4d5]"></td>
              <td className="border border-1 p-2  border-black bg-[#fbe4d5]"></td>
            </tr>
          </tbody>
        </table>
      </div>
      <br />
      <br />
      <h2 className="font-semibold">Legal Grounds of Collecting Personal Data During Your Session</h2>
      <br />
      <p className="pl-10 text-sm">
        As per our obligations arising from the terms & conditions and our legitimate interest in customer satisfaction, we are
        required to provide you and other users, with secure, operational and user-friendly access to our Platform. Furthermore,
        we are required by personal data protection laws to provide you with a secure access. For these reasons, we collect the
        personal data listed above. Only if you explicitly consent for receiving marketing messages we use the phone number data
        for sending you marketing messages as explained above.
      </p>
      <br />
      <br />
      <h1 className="font-bold">DATA COLLECTED FROM THE THIRD PARTIES</h1>
      <br />
      <p>
        PICK SLAM may receive certain information about you from the third-party application provider if you use one of the
        services connected to third-party applications or connect from any third-party application such as Apple, Google, Amazon,
        Microsoft Azure. The information received depends on the third party application, your privacy settings on the third party
        application, and your friends' privacy settings, if any
      </p>
      <br />
      <p>
        If you access our Products and Services through a third-party application or link our Products and Services to a
        third-party application, you will also need to read the terms of service and privacy policy of that third-party
        application. If you are not sure about the scope of the information a third-party application is sharing with us, you can
        access the third-party application to learn more about their privacy practices and the options available to you regarding
        the transfer of your data.
      </p>
      <br />
      <p>
        To manage the information PICK SLAM receives about you through a third-party application or platform, such as Apple,
        Google, Amazon or Microsoft Azure, you will need to follow their instructions to update your information and change your
        privacy settings.
      </p>
      <br />
      <p>
        You can also manage many aspects of collection and usage of information from your mobile device by visiting the "settings"
        page of your mobile device or web browser and reviewing the access permissions of each PICK SLAM application.
      </p>
      <br />
      <p>
        When PICK SLAM receives your information from a third-party application / platform or your mobile device, this information
        is stored and used by PICK SLAM. You can access and update information as described above.
      </p>
      <br />
      <br />
      <h1 className="font-bold">TRANSFER OF THE PERSONAL DATA COLLECTED</h1>
      <br />
      <p>
        In order for (i) you to have secure and easy access to our platform, (ii) you to have continuous user support as required
        from time to time and (iii) us to develop technical features to enhance the end-user experience in line with changing
        needs and demands, we provide our software and services to you in cooperation with our group companies operating in
        different countries around the world. For this reason, subject to our data privacy policies, guidance and restrictions,
        our group companies in different countries also process your abovementioned personal data.
      </p>
      <br />
      <p>
        PICK SLAM utilizes the infrastructures of Amazon AWS/Microsoft Azure whose servers are located in the United States of
        America. We also utilize services of third-party service providers located at different countries for IT and
        cyber-security matters. The reason for choosing the aforementioned servers/infrastructures and third-party service
        providers outside your country is to utilize leading servers/infrastructures and services on a global scale in order to
        provide you with a well-run, user-friendly and secure platform. In accordance with the personal data protection laws, such
        utilization of servers, infrastructures and services of third-party service providers located outside your country may be
        considered as transfer of personal data outside your country
      </p>
      <br />
      <p>
        If you wish to be informed about the discounts, campaigns and promotions of our sponsors, group companies, contracted
        organizations and football clubs that you have stated to be the supporter of and if you give your explicit consent in this
        regard, we will share your e-mail address information with these companies/ clubs/organizations in order for them to send
        you commercial electronic messages with advertisement, campaign, promotion and marketing
      </p>
      <br />
      <br />
      <h1 className="font-bold">HOW TO USE YOUR APPLICATION RIGHT REGARDING YOUR PERSONAL DATA</h1>
      <br />
      <p>
        If you wish to permanently delete your account and all associated data, please send an email to info@pickslam.com with the
        game you play and your account information. We will make commercially reasonable efforts to fulfill your request, but we
        may deny requests that are unreasonably repetitive, disproportionate, or infringe on the privacy of others.
      </p>
      <br />

      <p>
        You may use your application right provided under personal data protection laws by sending a petition to 48W 48th Street,
        Suite :1204, New York, NY-10036, United States by courier or to info@pickslam.com by e-mail or by other means provided
        under personal data protection laws.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
